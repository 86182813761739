import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Card, Col, Form, InputGroup, Row,} from "react-bootstrap";
import TableUtilities from "components/dist/tables/table-utilities";
import {useParams} from "react-router-dom";
import InventoryService, {
    InventoryItem,
    ItemPrice,
    InventoryItemStatus,
} from "../../modules/dashboard/inventory/inventory.service";
import {ConfirmModal, ItemCard} from "components";
import {toast} from "react-hot-toast";
import axios from "axios";
import {Helmet} from "react-helmet-async";

const ViewInventoryItemPage = () => {
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
    const [item, setItem] = useState<InventoryItem | undefined>(undefined)
    const [activeItemPrice, setActiveItemPrice] = useState<ItemPrice | undefined>(undefined)
    const [newPrice, setNewPrice] = useState<number>()
    const [newPricePaid, setNewPricePaid] = useState<number>()
    const [pricePaidInput, setPricePaidInput] = useState<string>('')

    const {id} = useParams();

    useEffect(() => {
        (async () => {
            await fetchItem()
        })()
    }, [])

    const fetchItem = async () => {
        if (!id) return;

        const item = await InventoryService.fetchItem(id)

        setItem(item)
        setNewPrice(item.price / 100)
        setNewPricePaid(item.pricePaid / 100)
        setPricePaidInput((item.pricePaid / 100).toFixed(2))

        if (item?.status == InventoryItemStatus.InQueue) {
            // fetch availability
            const price = await InventoryService.fetchItemSizeAvailability(item.product.id, item.size, item.shape)
            setActiveItemPrice(price)
        }
    }

    const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPrice(Number(event.target.value))
    }

    const handlePricePaidChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (isValidDecimal(value)) {
            setPricePaidInput(value);
            setNewPricePaid(value === '' ? undefined : parseFloat(value) || undefined);
        }
    };

    const onWithdrawItemClicked = () => {
        setConfirmModalVisible(true);
    }

    const closeConfirmModal = () => {
        setConfirmModalVisible(false);
    }

    const onWithdrawConfirm = async () => {
        toast.loading('Please wait...', {id: 'withdrawalRequest'})

        try {
            const response = await axios.post('/api/v1/withdraw/request', {
                item_id: [item?.id]
            })

            toast.success(response.data.message, {
                id: 'withdrawalRequest',
                duration: 8000
            })

            await fetchItem()

        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'withdrawalRequest',
                duration: 5000
            })
        }

        closeConfirmModal();
    }

    const onReprintLabel = async () => {
        toast.loading('Please wait...', {id: 'reprintLabel'})

        try {
            const response = await axios.post('/api/v1/label/item/generate', {
                item_id: item?.id
            })

            toast.success(response.data.message, {
                id: 'reprintLabel',
                duration: 8000
            })

        } catch (e: any) {
            const message = e.response.data.details?.message ?? "Failed to reprint label";

            toast.error(message, {
                id: 'reprintLabel',
                duration: 5000
            })

            throw e;
        }
    }

    const onWithdrawCancel = () => {
        closeConfirmModal();
    }

    const updatePrice = async () => {
        toast.loading('Please wait...', {id: 'updatePrice'})

        try {
            const response = await axios.patch('/api/v1/item/update/price', {
                item_id: item?.id,
                price: newPrice
            })

            toast.success(response.data.message, {
                id: 'updatePrice',
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'updatePrice',
                duration: 5000
            })
        }
    }

    const updatePricePaid = async () => {
        if (newPricePaid === undefined) return;

        toast.loading('Please wait...', {id: 'updatePricePaid'})

        try {
            const response = await axios.patch('/api/v1/item/update/price_paid', {
                item_id: item?.id,
                price: newPricePaid
            })

            toast.success(response.data.message, {
                id: 'updatePricePaid',
                duration: 8000
            })
        } catch (e: any) {
            toast.error(e.response.data.details.message, {
                id: 'updatePricePaid',
                duration: 5000
            })
        }
    }

    const isStatusActiveOrInQueue = () => {
        return item?.status == InventoryItemStatus.Active || item?.status == InventoryItemStatus.InQueue;
    }

    const canUpdatePrice = () => {
        return item?.storeOwned && isStatusActiveOrInQueue();
    }

    const profitPadding = () => {
        return isStatusActiveOrInQueue() ? 'ps-20' : '';
    }

    const isValidDecimal = (value: string): boolean => {
        return /^\d*\.?\d{0,2}$/.test(value);
    };

    return (
        <>
            <ConfirmModal visible={confirmModalVisible}
                          message={'Are you sure you want to withdraw this item?'}
                          onConfirm={onWithdrawConfirm}
                          onCancel={onWithdrawCancel}
            />

            <Row>
                <Col xs={12} md={4} className='pe-3 pe-sm-5 mb-5 mb-md-0'>
                    <ItemCard
                        imageUrl={item?.product.imageUrl ?? ''}
                        subtitle={item?.product.colorway ?? ''}
                        productCode={item?.product.sku ?? ''}
                        title={item?.product.title ?? ''}
                        location={item?.location?.name ?? ''}
                        condition={item?.shape ?? ''}
                        status={item?.status ?? ''}
                        productId={item?.product.id ?? ''}
                        ownership={item?.storeOwned ? 'Store Owned' : item?.user.fullName}
                        ownerId={item?.storeOwned ? undefined: item?.user.id}
                    />
                </Col>

                <Col xs={12} md={8}>
                    <Card className={'mb-5'} title=''>
                        {isStatusActiveOrInQueue() && (
                            <div className='d-flex align-items-center justify-content-end me-7 gap-5'>
                                <Button variant={'secondary'} onClick={() => onReprintLabel()}>
                                    Reprint Label
                                </Button>

                                <Button variant={'danger'} onClick={() => onWithdrawItemClicked()}>
                                    Withdraw Item
                                </Button>
                            </div>
                        )}

                        <Card.Body>
                            <div className='table-responsive'>
                                <table className="table table-row-dashed align-middle text-nowrap" style={{tableLayout: 'fixed'}}>
                                    <thead>
                                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                        <th>Size</th>
                                        <th>Price Paid</th>
                                        <th>List Price</th>
                                        <th className={profitPadding()}>Profit</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className='fw-bold'>
                                        <td>{item?.size}</td>
                                        <td>
                                            {canUpdatePrice() ? (
                                                <InputGroup className='flex-nowrap'>
                                                    <InputGroup.Text id="basic-addon1"
                                                                     className='border-0 pe-1 py-1'>$</InputGroup.Text>
                                                    <Form.Control
                                                        placeholder="0"
                                                        aria-label="Price paid"
                                                        type="text"
                                                        inputMode="decimal"
                                                        className='form-control-solid py-1 ps-0 pe-1 flex-grow-0'
                                                        style={{width: '70px', minWidth: '70px'}}
                                                        value={pricePaidInput}
                                                        onChange={handlePricePaidChange}
                                                    />
                                                    <Button variant="secondary"
                                                            className='d-flex justify-content-center align-items-center py-0 px-3'
                                                            onClick={() => updatePricePaid()}
                                                    >
                                                        <i className='fa fa-check p-0'/>
                                                    </Button>
                                                </InputGroup>
                                            ) : (
                                                TableUtilities.formatCurrencyCents(item?.pricePaid ?? 0)
                                            )}
                                        </td>
                                        <td>
                                            <div style={{ position: 'relative' }}>
                                                {canUpdatePrice() ? (
                                                    <>
                                                        <InputGroup className='flex-nowrap'>
                                                            <InputGroup.Text id="basic-addon1" className='border-0 pe-1 py-1'>$</InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="0"
                                                                aria-label="List price"
                                                                className='form-control-solid py-1 ps-0 pe-1 flex-grow-0'
                                                                style={{width: '70px', minWidth: '70px'}}
                                                                value={newPrice}
                                                                onChange={handlePriceChange}
                                                            />
                                                            <Button variant="primary"
                                                                    className='d-flex justify-content-center align-items-center py-0 px-3'
                                                                    onClick={() => updatePrice()}
                                                            >
                                                                <i className='fa fa-check p-0'/>
                                                            </Button>
                                                        </InputGroup>
                                                        {item?.status == InventoryItemStatus.InQueue && activeItemPrice?.price != null && (
                                                            <span className="text-muted fs-7" style={{ position: 'absolute', top: '100%', left: 2, marginTop: '4px' }}>
                                                                Lowest Price: ${activeItemPrice?.price / 100}
                                                            </span>  
                                                        )}
                                                    </>
                                                ) : (
                                                    TableUtilities.formatCurrencyCents(item?.price ?? 0)
                                                )}
                                            </div>
                                        </td>
                                        <td className={profitPadding()}>{TableUtilities.formatCurrencyCents(item?.profitAmount ?? 0)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <h2>Events</h2>
                            </Card.Title>

                            <ul className="timeline mb-5">
                                {item?.event.map((event, index) => (
                                    <li key={index}>
                                        <span className={'fw-bold'}>{event.title}</span>
                                        <p className='text-muted'>{event.description}</p>
                                        <small
                                            className="text-muted">{TableUtilities.formatDateLong(event.createdAt)}</small>
                                        <span className='badge badge-light ms-3'>{event.user?.fullName}</span>
                                    </li>
                                ))}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const ViewInventoryItemWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INVENTORY'})}</PageTitle>
            <Helmet>
                <title>View Item</title>
            </Helmet>
            <ViewInventoryItemPage/>
        </>
    )
}

export default ViewInventoryItemWrapper